import { defineStore } from "pinia";
import type { OpeningHours, OpeningHoursDays } from "../../models";

const _24mins = 24 * 60;
const STORE_PROVIDER_KEY = "__opening-hours-editor-store__";

export const openingHoursDefaults: OpeningHours = {
  startOfWeek: 0,
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
  exceptions: {},
};

export const timeItems = (
  from = 0,
  to = _24mins,
  use24hr = false,
  intervals = 5
) => {
  // TODO: normalise from and to with intervals

  const size = (to - from) / intervals; // 12hrs by intervals

  return of(size).map((i) => {
    const time = i * intervals + from;
    return {
      time,
      display: formatMinutes(time, use24hr),
    };
  });
};

const useEditorStore = (id: string) => {
  const s = defineStore("opening-hours:" + id, () => {
    const hours = ref<OpeningHours>({ ...openingHoursDefaults });

    const set = (input: Partial<OpeningHours>) => {
      hours.value = {
        ...openingHoursDefaults,
        ...input,
      };
    };

    const useDay = <K extends OpeningHoursDays>(day: K) => {
      return computed({
        set(v: OpeningHours[K]) {
          hours.value[day] = filterNilItems(v);
        },
        get(): OpeningHours[K] {
          return filterNilItems(hours.value[day]);
        },
      });
    };

    return {
      hours,
      set,
      useDay,
    };
  });

  return s();
};

export const provideStore = () => {
  const id = uuid();
  provide(STORE_PROVIDER_KEY, id);
  return useEditorStore(id);
};

export const injectStore = () => {
  const id = inject(STORE_PROVIDER_KEY) as string;
  return useEditorStore(id);
};
